import React, {useEffect, useState} from 'react';
import {navigate} from "gatsby";
import './Login.scss';

import {useQueryParam, StringParam} from "use-query-params";
import {login, getRestCountries} from '@repository/UserRepository';
import {storeToken} from '@repository/client';

function Login() {
  const [isRegisterSuccess, setIsRegisterSuccess] = useQueryParam("is_register_success", StringParam);
  const [inputs, setInputs] = useState({
    identifier: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [countriesMix, setCountriesMix] = useState([]);
  const [isLoadData, setIsLoadData] = useState(true);
  const [isShowPassword, setIsShowPassword] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    handleGetRestCountries();
  }, []);

  const handleGetRestCountries = () => {
    setIsLoadData(true);
    getRestCountries()
      .then(res => {
        setCountriesMix(res.data)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoadData(false));
  }

  const handleOnInputsChanged = (e) => {
    let label = e.target.name;
    let value = e.target.value;
    setInputs((currentValue) => (
      {
        ...currentValue,
        [label]: value,
      }
    ));
  }

  const handleOnLogin = () => {
    setIsLoading(true);
    setErrors({});
    login(inputs)
      .then((res) => {
        let user = res.data.user;

        let localStorageCountryString = localStorage.getItem('country');
        if (localStorageCountryString === null) {
          let mCountry = countriesMix.find((countryMix) => countryMix.alpha3Code === user.country.code);
          localStorage.setItem('country', JSON.stringify(mCountry));
        }
        storeToken(res.data.token);
        navigate("/browse");
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.data.status_code === 422) {
          setErrors(err.response.data.errors);
          console.log(err.response.data.errors);
        } else {
          setErrors({
            identifier: ['The e-mail or password you entered is incorrect. Please try again.'],
          })
          console.log(err.response);
        }
        setIsLoading(false);
      })
  }

  const hasValidInputs = () => {
    if (
      !inputs.identifier ||
      !inputs.password
    ) {
      return false;
    }
    return true;
  }

  return (
    <div id="login" className="container py-5 d-flex flex-column flex-grow-1">
      {isRegisterSuccess && (
        <div className="asap-body text-philadelphia-blue py-2 px-3 alert-register mb-4">
          Registered successfully. You may login now!
        </div>
      )}
      {isLoadData ? (
        <div className="mdi mdi-loading mdi-spin mdi-48px m-auto" />
      ) : (
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 d-flex flex-column">
            <div className="dosis-title-1 mx-auto">
              Login to Cerebro
            </div>
            <div className="divider bg-abalone-gray align-self-center my-4" />
            <div className={`form-group d-flex flex-column-reverse ${errors.identifier && 'mb-1'}`}>
              <input
                id="email"
                name="identifier"
                type="email"
                className="form-control"
                placeholder="Enter your username or e-mail address"
                value={inputs.identifier}
                onChange={handleOnInputsChanged}
                onKeyPress={event => {
                  if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                    handleOnLogin();
                  }
                }}
                required
              />
              <label htmlFor='email' className='input-label'>
                Username / E-mail address
              </label>
            </div>
            {errors.identifier && (
              <div className="asap-caption-1 text-apple-red mb-3">{errors.identifier[0]}</div>
            )}
            <div className={`form-group d-flex flex-column-reverse position-relative ${errors.password && 'mb-1'}`}>
              <input
                id="password"
                type={isShowPassword ? "text" : "password"}
                name="password"
                className="form-control input-password"
                placeholder="Enter your password"
                value={inputs.password}
                onChange={handleOnInputsChanged}
                onKeyPress={event => {
                  if (event.key === 'Enter' && !(isLoading || !hasValidInputs())) {
                    handleOnLogin();
                  }
                }}
                required
              />
              <label htmlFor='password' className='input-label'>
                Password
              </label>
              <div
                className={`text-steel-gray mdi ${isShowPassword ? 'mdi-eye' : 'mdi-eye-off'} mdi-24px icon-eye`}
                onClick={() => {
                  setIsShowPassword((prevValue) => !prevValue);
                }}
              />
            </div>
            {errors.password && (
              <div className="asap-caption-1 text-apple-red mb-3">{errors.password[0]}</div>
            )}
            <div className="asap-caption text-azure-blue cursor-pointer mb-4" onClick={() => navigate('/forgot-password')}>
              Forgot password?
            </div>
            <button
              className="button-md button-primary mb-3"
              onClick={handleOnLogin}
              disabled={isLoading || !hasValidInputs()}>
              {isLoading ? <div className="mdi mdi-loading mdi-spin" /> : "Login Now"}
            </button>
            <div className="align-self-center asap-body text-iron-gray">
              Don’t have an account?{' '}
              <u className="text-azure-blue text-underline cursor-pointer" onClick={() => navigate('/register')}>
                Register now
              </u>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
